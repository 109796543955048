
























import { defineComponent } from "@vue/composition-api";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { environmentVariables } from "@monorepo/utils/src/variables/environmentVariables";
import { educationItems, IEducationItem } from "@monorepo/app/src/constants/educationItems";

type item = { top: string; left: string; title: string; iconPosition: string } | null;
export default defineComponent({
  name: "EducationPanel",
  data() {
    return {
      educationItems,
    };
  },
  beforeCreate() {
    this.$store.commit("auth/setIsOpenEducation", true);
  },
  created() {
    this.blockScroll();
    window.addEventListener("scroll", this.blockScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.blockScroll);
    this.$store.commit("auth/setIsOpenEducation", false);
  },
  computed: {
    icon() {
      return `${environmentVariables.BASE_URL}assets/images/navigation/tooltip.svg`;
    },
    items(): item[] {
      return educationItems
        .map((item: IEducationItem) => {
          const element = document.querySelector(`[data-education-id=${item.id}]`);
          if (element) {
            const config = element.getBoundingClientRect();
            const top = config.top + (item.position === "bottom" ? config.height + 9 : -config.height - 4) + (item.additionalTopOffset || 0);
            const left = config.left + (item.alignToLeft ? 0 : config.width - item.width) + item.additionalLeftOffset;
            return {
              id: item.id,
              top: top + "px",
              left: left + "px",
              iconPosition: item.iconPosition,
              title: item.title,
            };
          }
          return null;
        })
        .filter((item) => !!item);
    },
  },
  methods: {
    blockScroll() {
      window.scrollTo(0, 0);
    },
    closeModal() {
      eventBus.$emit(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, formModalPayload(false, ""));
    },
  },
});
