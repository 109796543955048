import { educationIds } from "@monorepo/utils/src/variables/educationIds";

export interface IEducationItem {
  title: string;
  iconPosition: "top" | "bottom";
  position: "top" | "bottom";
  width: number;
  additionalLeftOffset: number;
  additionalTopOffset?: number;
  id: string;
  alignToLeft?: boolean;
}

export const educationItems: IEducationItem[] = [
  {
    title: "Добавление нового пользователя",
    iconPosition: "top",
    position: "bottom",
    width: 212,
    additionalLeftOffset: 4,
    id: educationIds.addElementUser,
  },
  {
    title: "Добавление нового фонда",
    iconPosition: "top",
    position: "bottom",
    width: 187,
    additionalLeftOffset: 21,
    id: educationIds.addElementFund,
  },
  {
    title: "Добавление нового архива",
    iconPosition: "top",
    position: "bottom",
    width: 187,
    additionalLeftOffset: 17,
    id: educationIds.addElementArchive,
  },
  {
    title: "Добавление нового ОИК",
    iconPosition: "top",
    position: "bottom",
    width: 187,
    additionalLeftOffset: 33,
    id: educationIds.addElementOik,
  },
  {
    title: "Добавление нового Системного Уведомления",
    iconPosition: "top",
    position: "bottom",
    width: 240,
    additionalLeftOffset: -36,
    id: educationIds.addElementNotification,
  },
  {
    title: "Диаграмма Ганта. Изменение дат периода передачи дел возможно с помощью перемещения столбчатых диаграмм",
    iconPosition: "bottom",
    position: "top",
    width: 700,
    additionalLeftOffset: -200,
    id: educationIds.gant,
  },
  {
    title: "Настройка отображения столбцов таблицы",
    iconPosition: "top",
    position: "bottom",
    width: 272,
    additionalLeftOffset: 18,
    id: educationIds.toggleTableCells,
  },
  {
    title: "Сохранение заданных фильтров в шаблон",
    iconPosition: "bottom",
    position: "top",
    width: 264,
    additionalLeftOffset: 10,
    id: educationIds.filterTemplates,
  },
  {
    title: "Все фильтры",
    iconPosition: "top",
    position: "bottom",
    width: 92,
    additionalLeftOffset: 10,
    id: educationIds.toggleFilters,
  },
  {
    title: "Количество записей в Системе",
    iconPosition: "top",
    position: "bottom",
    width: 174,
    additionalLeftOffset: 0,
    id: educationIds.numberOfEvents,
  },
  {
    title: "Сгенерировать ТК",
    iconPosition: "bottom",
    position: "top",
    width: 174,
    additionalLeftOffset: 0,
    id: educationIds.generateTk,
  },
  {
    title: "Перенести ТК",
    iconPosition: "bottom",
    position: "top",
    width: 174,
    additionalLeftOffset: 0,
    id: educationIds.transferTk,
  },
  {
    title: "Сгенерировать ТК проекта описи дел",
    iconPosition: "bottom",
    position: "top",
    width: 320,
    additionalLeftOffset: -50,
    id: educationIds.generateProjectTk,
  },
  {
    title: "Перенести ТК проекта описи дел",
    iconPosition: "bottom",
    position: "top",
    width: 320,
    additionalLeftOffset: 0,
    id: educationIds.transferProjectTk,
  },
  {
    title: "Перенести ТК ЭАД",
    iconPosition: "bottom",
    position: "top",
    width: 160,
    additionalLeftOffset: 0,
    id: educationIds.transferEadTk,
  },
  {
    title: "Очистить папку",
    iconPosition: "bottom",
    position: "top",
    width: 160,
    additionalLeftOffset: 0,
    id: educationIds.clearFolder,
  },
  {
    title: "Очистить папку",
    iconPosition: "bottom",
    position: "top",
    width: 160,
    additionalLeftOffset: 0,
    id: educationIds.clearFolderTk,
  },
  {
    title: "Удаление информации по тк",
    iconPosition: "bottom",
    position: "top",
    width: 210,
    additionalLeftOffset: 0,
    id: educationIds.clearTkInfo,
  },
  {
    title: "Удаление всех сущностей",
    iconPosition: "bottom",
    position: "top",
    width: 210,
    additionalLeftOffset: 0,
    id: educationIds.clearAllEntities,
  },
  {
    title: "Удаление всех справочников",
    iconPosition: "bottom",
    position: "top",
    width: 230,
    additionalLeftOffset: 0,
    id: educationIds.clearAllCatalogs,
  },
  {
    title: "Добавление справочников по умолчанию",
    iconPosition: "bottom",
    position: "top",
    width: 280,
    additionalLeftOffset: 0,
    id: educationIds.addDefaultCatalogs,
  },
  {
    title: "Настройка параметров учетной формы",
    iconPosition: "bottom",
    position: "top",
    width: 380,
    additionalLeftOffset: 120,
    additionalTopOffset: 30,
    id: educationIds.listViewSettings,
  },
  {
    title: "Построить учётную форму",
    iconPosition: "bottom",
    position: "top",
    width: 280,
    additionalLeftOffset: 70,
    id: educationIds.formListView,
  },
  {
    title: "Экспорт в PDF или DOCX",
    iconPosition: "top",
    position: "bottom",
    width: 180,
    additionalLeftOffset: 38,
    additionalTopOffset: 4,
    id: educationIds.exportListView,
  },
  {
    title: "Состояние сервисов технической платформы",
    iconPosition: "bottom",
    position: "top",
    width: 320,
    additionalLeftOffset: 30,
    alignToLeft: true,
    additionalTopOffset: -30,
    id: educationIds.servicesState,
  },
  {
    title: 'Параметры графика "Время обработки входящих ТК"',
    iconPosition: "top",
    position: "bottom",
    width: 380,
    additionalLeftOffset: 38,
    id: educationIds.timeGraphSettings,
  },
  {
    title: "Блок общесистемных настроек",
    iconPosition: "bottom",
    position: "top",
    width: 320,
    additionalLeftOffset: 30,
    alignToLeft: true,
    additionalTopOffset: -10,
    id: educationIds.generalSectionSettings,
  },
  {
    title: "Блок настройки подсистемы мониторинга и администрирования",
    iconPosition: "bottom",
    position: "top",
    width: 320,
    additionalLeftOffset: 30,
    alignToLeft: true,
    additionalTopOffset: -10,
    id: educationIds.administrationSettings,
  },
  {
    title: "Блок настройки подсистемы приёма и обработки",
    iconPosition: "bottom",
    position: "top",
    width: 320,
    additionalLeftOffset: 30,
    alignToLeft: true,
    additionalTopOffset: -10,
    id: educationIds.containerWorkerSettings,
  },
  {
    title: "Блок настройки подсистемы создания и ведения справочников",
    iconPosition: "bottom",
    position: "top",
    width: 320,
    additionalLeftOffset: 30,
    alignToLeft: true,
    additionalTopOffset: -10,
    id: educationIds.dictionarySettings,
  },
  {
    title: "Блок настройки подсистемы учета",
    iconPosition: "bottom",
    position: "top",
    width: 320,
    additionalLeftOffset: 30,
    alignToLeft: true,
    additionalTopOffset: -10,
    id: educationIds.documentRegistrationSettings,
  },
  {
    title: "Блок настройки хранилища данных ХД ЦХЭД",
    iconPosition: "bottom",
    position: "top",
    width: 320,
    additionalLeftOffset: 30,
    alignToLeft: true,
    additionalTopOffset: -10,
    id: educationIds.filestorageSettings,
  },
  {
    title: "Блок настройки подсистемы интеграции с хранилищем данных Уполномоченного органа",
    iconPosition: "bottom",
    position: "top",
    width: 320,
    additionalLeftOffset: 30,
    alignToLeft: true,
    additionalTopOffset: -10,
    id: educationIds.hduoIntegrationSettings,
  },
  {
    title: "Блок настройки информационно-поисковой подсистемы",
    iconPosition: "bottom",
    position: "top",
    width: 320,
    additionalLeftOffset: 30,
    alignToLeft: true,
    additionalTopOffset: -10,
    id: educationIds.informationRetrievalSettings,
  },
  {
    title: "Блок настройки подсистемы информационной безопасности",
    iconPosition: "bottom",
    position: "top",
    width: 320,
    additionalLeftOffset: 30,
    alignToLeft: true,
    additionalTopOffset: -10,
    id: educationIds.informationSecuritySettings,
  },
  {
    title: "Блок настройки подсистемы аналитики и отчетности",
    iconPosition: "bottom",
    position: "top",
    width: 320,
    additionalLeftOffset: 30,
    alignToLeft: true,
    additionalTopOffset: -10,
    id: educationIds.reportSettings,
  },
  {
    title: "Переключение отображения",
    iconPosition: "bottom",
    position: "top",
    width: 180,
    additionalLeftOffset: 10,
    additionalTopOffset: -10,
    id: educationIds.tkLogType,
  },
  {
    title: "Добавление новой роли",
    iconPosition: "top",
    position: "bottom",
    width: 187,
    alignToLeft: true,
    additionalLeftOffset: -40,
    id: educationIds.addRole,
  },
  {
    title: "Удаление роли",
    iconPosition: "top",
    position: "bottom",
    width: 125,
    additionalLeftOffset: 33,
    id: educationIds.deleteRole,
  },
  {
    title: "Отобразить настройки роли",
    iconPosition: "top",
    position: "bottom",
    width: 160,
    alignToLeft: true,
    additionalLeftOffset: -50,
    id: educationIds.showRoleSettings,
  },
  {
    title: "Пользователи, которым назначена роль",
    iconPosition: "top",
    position: "bottom",
    width: 300,
    alignToLeft: true,
    additionalLeftOffset: -50,
    id: educationIds.roleUsersList,
  },
  {
    title: "Переключение на вкладку пользователи",
    iconPosition: "bottom",
    position: "top",
    width: 280,
    additionalLeftOffset: 10,
    id: educationIds.usersTab,
  },
  {
    title: "Переключение на вкладку Роли",
    iconPosition: "bottom",
    position: "top",
    width: 180,
    alignToLeft: true,
    additionalLeftOffset: -10,
    id: educationIds.rolesTab,
  },
  {
    title: "Переключение на вкладку Журнал",
    iconPosition: "bottom",
    position: "top",
    width: 280,
    additionalLeftOffset: 10,
    id: educationIds.syncLogJournalTab,
  },
  {
    title: "Переключение на вкладку ОИК",
    iconPosition: "bottom",
    position: "top",
    width: 180,
    alignToLeft: true,
    additionalLeftOffset: -10,
    id: educationIds.syncLogOikTab,
  },
  {
    title: "Переключение на представление в виде дерева",
    iconPosition: "bottom",
    position: "top",
    width: 300,
    additionalLeftOffset: 7,
    id: educationIds.treeViewTab,
  },
  {
    title: "Переключение на представление в виде таблицы",
    iconPosition: "bottom",
    position: "top",
    width: 300,
    additionalLeftOffset: -40,
    id: educationIds.tableViewTab,
  },
];
